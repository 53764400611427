import logo from "../../Assets/Images/ned-logo.png";
import "./Header.scss";
import UserLoginDetails from "../../Components/UserLogin/UserLoginDetails";
import UtcCurrentDateTime from "../../Components/UTCDateTime/UtcCurrentDateTime";
const Header = () => {
  return (
    <div className="header-section">
      <div className="row">
        <div className="col-md-4">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        
        <div className="col-md-8">
          <div className="time-with-userDetails">
          <div className="time-with-notification">
            <span className="timezone"> UTC Time:  <UtcCurrentDateTime /></span>
            <span className="notification"><UserLoginDetails /></span>
            <span className="it-help"><a target="_blank" href="https://maersk.service-now.com/end_user_portal?id=sc_cat_item&sys_id=f74c98aefb6a4a90c360fe57aeefdc4e">IT help</a></span>
          </div>
          </div>
        
        </div>
      </div>
     </div>
  );
};
export default Header;
