import React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
interface TableProps {
  originalJSON?: object[];
}
const Table = ({ originalJSON }: TableProps) => {
  const convertColumns = (jsonData: any) => {
    if (jsonData?.length > 0) {
      const columns: any = [
        {
          name: "Service",
          selector: (row: any) => row.serviceName,
        },
        {
          name: "Vessel Name",
          selector: (row: any) => row.vesselName,
        },
      ];
      jsonData[0]?.impactOptions?.forEach((option: any) => {
        columns.push({
          name: formatTheHeaderName(option.optionName),
          selector: (row: any) => row[option.optionName],
        });
      });

      return columns;
    }
  };

  const formatTheHeaderName = (headerName: string) => {
    const capitalizeHeaderName = headerName.charAt(0).toUpperCase() + headerName.slice(1);
    const regexPattern = /[^0-9](?=[0-9])/g;
    return capitalizeHeaderName.replace(regexPattern, '$& ');
  }
  
  const covertedColums = convertColumns(originalJSON);
  const columns: TableColumn<any>[] = covertedColums;
  const convertData = (jsonData: any) => {
    const convertedArray: any = [];
    jsonData?.forEach((obj: any) => {
      const convertedObj: any = {
        serviceName: obj.serviceName,
        vesselName: obj.vesselName,
      };
      obj?.impactOptions?.forEach((option: any) => {
        convertedObj[option.optionName] = option.optionValue;
      });
      convertedArray.push(convertedObj);
    });
    return convertedArray;
  };

  const convertedData = convertData(originalJSON);
  const total: any = {
    serviceName: "Impact",
  };
  // type any is used becasue of object contains different type
  convertedData?.forEach((service: any) => {
    Object.keys(service).forEach((key: any) => {
      if (key !== "serviceName" && key !== "vesselName") {
        total[key] = parseFloat(
          ((total[key] || 0) + (service[key] as any)).toFixed(2)
        );
      }
    });
  });
  convertedData?.push(total);

  return (
    <div className="tableData table-section">
      <DataTable columns={columns} data={convertedData} />
    </div>
  );
};
export default Table;
