import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./FBPValidation.scss";
import Table from "./AddOnComponent/Table";
import PermutationTable from "./AddOnComponent/PermutationTable";
import StepIndicator from "../../Components/StepIndicator/StepIndicator";
import { AppString } from "../../utils/AppString";
import { appRoles } from "../../utils/Constants";
import PageLoader from "../../Components/PageLoader/PageLoader";
import ProposalDeatils from "./AddOnComponent/ProposalDeatils";
import {
  TextArea,
  ReactSelect,
  Avatar,
  Button,
  toastEmitter,
} from "@anchor/react-components/dist/lib/components";
import { getAPI, postAPI } from "../../Services/APIservices";
import ConfirmationModalPopUp from "./AddOnComponent/ConfirmationModalPopUp";
const FBPvalidation = () => {
  const history = useNavigate();
  const userRole = sessionStorage.getItem("userRole");
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const requestID = params.get("requestId");
  const [mainData, setMainData] = useState<any>([]);
  const [isPageLoader, setIsPageLoader] = useState<boolean>(false);
  const [isSubmitModal, setIsSubmitModal] = useState<boolean>(false);
  const [dtcoComments, setDtcoComments] = useState<any>("");
  

  const handleSubmitValidation = async () => {
      setIsSubmitModal(true);
  };
  const modalSubmit = async () => {
    const payload = {
      dtcoImpactJson: "string",
      dtcoImpact: mainData?.dtcoImpact,
      comment: dtcoComments === null ? "" : dtcoComments,
      isDraftRequest: false,
      maxNopat: 0,
      fbpReviewer: mainData?.fbpReviewer,
      DeploymentHeadReviewer: mainData?.deploymentHeadReviewer,
    };
    const response = await postAPI(
      `DTCOSummary/SaveDTCORequestProposal/${requestID}`,
      payload
    );
    if (response === true) {
      toastEmitter(
        {
          className: "customClassName",
          title: AppString.savedSuccessfullyToastComment,
        },
        { type: "success" }
      );
      history(`/DeploymentValidation?requestId=${requestID}`);
    } else {
      toastEmitter(
        { className: "customClassName", title: AppString.errorToastComment },
        { type: "error" }
      );
    }
  };
  const getMainData = async () => {
    setIsPageLoader(true);
    const response = await getAPI(
      `dtcosummary/GetReviewerDetailForDtcoRequestProposal/${requestID}`
    );
    setMainData(response);
    setDtcoComments(response?.comment);
    const tradeOwnerResponse = response?.tradeOwnerReviewers?.map(
      (item: any) => ({
        ...item,
        isActive: false,
      })
    );
    setIsPageLoader(false);
  };
  const handleSaveDraft = async () => {
    const payload = {
      dtcoImpactJson: "string",
      dtcoImpact: mainData?.dtcoImpact,
      comment: dtcoComments === null ? "" : dtcoComments,
      isDraftRequest: true,
      maxNopat: 0,
      fbpReviewer: mainData?.fbpReviewer,
      DeploymentHeadReviewer: mainData?.deploymentHeadReviewer,
    };
    const response = await postAPI(
      `DTCOSummary/SaveDTCORequestProposal/${requestID}`,
      payload
    );
    if (response === true) {
      toastEmitter(
        { className: "customClassName", title: "Saved successfully" },
        { type: "success" }
      );
    }
  };
  useEffect(() => {
    if (userRole !== appRoles.DeploymentUser) {
      history("/AccessDenied");
    }
    getMainData();
  }, []);
  return (
    <div className="fbpvalidation-page p-3 ">
      <PageLoader isLoader={isPageLoader} />
      <h1 className="header-text mb-3"> Send to Reviewers for Validation</h1>
      <div className="bck-button">
        <Button
          label="DTCO Proposal"
          appearance="alt"
          fit="small"
          icon="arrow-left"
          variant="filled"
          onClick={() => history(`/DtcoOverviewPage/${requestID}`)}
        ></Button>
      </div>
      <StepIndicator
        requestID={requestID}
        triggerPoint={isPageLoader}
      ></StepIndicator>
      <div className="data-section">
        <ProposalDeatils
          reuqestName={mainData?.dtcoRequestData?.requestName}
          createdBy={mainData?.dtcoRequestData?.createdBy}
          driversCategory={mainData?.dtcoRequestData?.dtcoCategoryName}
          dtcoType={mainData?.dtcoRequestData?.dtcoTypeName}
        />
        <div className="tableData-section mb-4">
          <h4 className="sub-header-text mb-3">Proposal from Deployment</h4>
          {mainData?.impactPermutations !== null && (
            <div className="permutation-table mb-3">
              <PermutationTable originalJSON={mainData?.impactPermutations} />
            </div>
          )}
          <Table originalJSON={mainData?.dtcoImpact} />
        </div>
        <div className="user-section mb-4">
        <h4 className="sub-header-text mb-3">Reviewers for validation</h4>
          <div className="row">
            <div className="col-md-4">
              <div className="reviewer">
                <div className="div-section">
                  <div className="img-section">
                    <Avatar
                      appearance="color1"
                      fit="medium"
                      imagesrc=""
                      info="Tooltip text"
                      initials="FBP"
                    />
                  </div>
                  <div className="name-section">
                    <div className="tittle">
                      Finance Business Partner 
                    </div>
                    <div className="name">
                      {mainData?.fbpReviewer?.reviewerName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="reviewer">
                <div className="div-section">
                  <div className="img-section">
                    <Avatar
                      appearance="color4"
                      fit="medium"
                      imagesrc=""
                      info="Tooltip text"
                      initials="DH"
                    />
                  </div>
                  <div className="name-section">
                    <div className="tittle">Deployment Head</div>
                    <div className="name">
                      {mainData?.deploymentHeadReviewer?.reviewerName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="comment-section mb-4">
        <h4 className="sub-header-text mb-3">Add a comment</h4>
          <div className="comment-box">
            <TextArea
              fit="medium"
              id="textInput"
              label=""
              value={dtcoComments}
              onChange={(e) => {
                setDtcoComments(e.target.value);
              }}
              rows={3}
              maxLength={4000}
            ></TextArea>
          </div>
        </div>
        <ConfirmationModalPopUp
          modalHeader="Send proposal"
          isOpen={isSubmitModal}
          modalBody="Are you sure you want to send the proposal ?"
          modalButton={{ primary: "Send", secondary: "Cancel" }}
          onClose={() => setIsSubmitModal(false)}
          submitFun={modalSubmit}
        />
        {mainData?.isDraftRequest !== false &&
          userRole === "DeploymentUser" && (
            <div className="button-section">
              <div className="action-div">
                <Button
                  appearance="default"
                  id="primary"
                  justifyItems="center"
                  label="Save Draft"
                  name="saveDraft"
                  fit="small"
                  onClick={() => handleSaveDraft()}
                  variant="outlined"
                />
                <Button
                  appearance="default"
                  id="primary"
                  justifyItems="center"
                  label="Send  for Validation"
                  name="SendValidation"
                  fit="small"
                  onClick={() => handleSubmitValidation()}
                  variant="filled"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
export default FBPvalidation;
