import { MultiSelect, ReactSelect, toastEmitter, Button } from "@anchor/react-components/dist/lib/components"
import { getAPI, postAPI } from "../../../Services/APIservices";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { DtcoModel, DtcoPerformaModel, DtcoVesselModel } from "../DtcoModel";
import { StatusListForPerforma } from "../../../utils/Constants";
import { AppString } from "../../../utils/AppString";
interface DtcoIterationProps {
  requestId?: string | number;
  handleDtcoData: (data: DtcoModel) => void;
  refreshRunPerforma?: boolean;
}
const DtcoIteration = ({ requestId, handleDtcoData, refreshRunPerforma}: DtcoIterationProps) => {
  const [vesselList, setVesselList] = useState<DtcoVesselModel[]>([]);
  const [selectedVessel, setSelectedVessel] = useState<DtcoVesselModel[]>([]);
  const [selectedPerforma, setSelectedPerforma] = useState<any>([]);
  const [isRunLoading, setIsRunLoading] = useState(false);
  const [errorPerforma, setErrorPerforma] = useState(false);
  const [errorVessel, setErrorVessel] = useState(false);
  const [isSelectLoader, setIsSelectLoader] = useState(false);
  const getStatusStyling = (status) => {
    if (StatusListForPerforma.includes(status)) {
      const resultStatus = "status-" + status;
      return resultStatus;
    } else {
      return "status-Default";
    }
  }
  const getServicePerformaDetails = async () => {
    setIsSelectLoader(true);
    const response = await getAPI(`DTCOSummary/GetVesselDetails`);
    if (response) {
      const res = response?.map((item) => {
        return {
          label: item.label + " (" + item.value + ")",
          value: item.value
        }
      })
      setVesselList(res);
      setIsSelectLoader(false);
    }
  }
  useEffect(() => {
    getServicePerformaDetails();
  }, [])
  const getOptionPerforma = (inputResponse: DtcoPerformaModel[]) => {
    const selectResponse = inputResponse?.map((item) => {
      return {
        label: <div className="dtco-service-name">{item.label}</div>,
        value: item.label,
        options: item?.options?.map((option) => {
          return {
            label: <div className="dtco-performa-header"><span className="dtco-roation-name">{option?.rotationId} {" "}</span><span style={{ position: 'absolute', right: 10 }} className={` ${"status-label"} ${getStatusStyling(option?.status)}`}
            >{option?.status}</span></div>,
            value: [option.value],
            isDisabled: option.disable
          }
        })
      }
    })
    return selectResponse;
  }
  const filterThePerforma = async (inputValue: string, callback): Promise<any> => {
    if (inputValue.length >= 3) {
      const response: DtcoPerformaModel[] = await getAPI(`DTCOSummary/GetServiceProformaDetails/${inputValue}/${requestId}`);
      if (response) {
        const selectResponse = getOptionPerforma(response);
        callback(selectResponse)
      } else {
        callback([])
      }
    }
  }

  const debounceSearch = debounce(filterThePerforma, 1000);
  const handleSaveRequestPerforma = async () => {
    setIsRunLoading(true);
    if (selectedPerforma === null || selectedPerforma === undefined || selectedPerforma?.length === 0) {
      setErrorPerforma(true);
    }

    if (selectedVessel?.length === 0) {
      setErrorVessel(true);
    }
    if (selectedPerforma?.value?.length > 0 && selectedVessel?.length > 0) {
      const payload = {
        rotationCode: selectedPerforma?.value?.[0],
        imoNumbers: selectedVessel?.map((item) => item.value)
      }    
      try {
        const postAPIResponse = await postAPI(`dtcosummary/SaveDTCORequestProformaData/${requestId}`, payload);
        if (postAPIResponse?.StatusCode !== 400 && postAPIResponse?.StatusCode !== 500 && postAPIResponse?.dtcoRequestData) {
          handleDtcoData(postAPIResponse);
        } else {
          toastEmitter(
            {
              className: "customClassName",
              title: "Error occured while Running DTCO proforma and vessel data.",
            },
            { type: "error" }
          );
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }    
      setSelectedPerforma([]);
    }
    setIsRunLoading(false);
  }
  const handleVesselChange = (e) => {
    setSelectedVessel(e);
    setErrorVessel(false);
  }
const handlePerformaChange = (e) => {
  setSelectedPerforma(e); setErrorPerforma(false) 
}
  return (
    <div className="dtco-iteration mb-3">
      <div className="iteration-section">
        <div className="row">

          <div className="col">
            <ReactSelect
              key = {`key-${selectedPerforma}${refreshRunPerforma}`}
              className="react-select"
              closeMenuOnSelect
              error={errorPerforma}
              errorMessage={AppString.errMsgForRotation}
              fit="small"
              hint="Please type 3 letter to get the list"
              id="selectPerforma"
              isClearable
              isAsync
              aria-label="Performa"
              loadOptions={(inputValue: string, callback) => { 
                  debounceSearch(inputValue, callback)
                }
              }
              label="Performa/Rotation ID/Service"
              name="selectCountry"
              onChange={handlePerformaChange}
              orientation="vertical"
              placeholder=""
              required
              suggestType="dynamic"
              value={selectedPerforma}
              width={100}    
              data-testid="selectPerforma"
            />
          </div>
          <div className="col">
            <MultiSelect
              className="multi-select"
              closeMenuOnSelect
              customNoOptionsMessage={<p>Not a valid option</p>}
              isLoading={isSelectLoader}
              error={errorVessel}
              errorMessage={AppString.errMsgForRotation}
              fit="small"
              isMulti
              id="selectVessel"
              isClearable
              isSearchable
              label="Vessel"
              name="selectVessel"
              onChange={handleVesselChange}
              options={vesselList}
              orientation="vertical"
              placeholder="Select by vessel"
              required
              suggestType="static"
              value={selectedVessel}
              hasSelectAll={false}
              width={100}
              aria-label="Vessel"
            />
          </div>
          <div className="col-md-1 dtco-run-btn">
            <Button
              fit="small"
              icon="play"
              id="run-btn"
              justifyItems="center"
              label="Run"
              name="run"
              loading={isRunLoading}
              title=""
              variant="filled"
              appearance="alt"
              width={100}
              onClick={handleSaveRequestPerforma}
              className="btn-run"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default DtcoIteration;