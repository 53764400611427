// React
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Anchor UI
import { toastEmitter } from "@anchor/react-components/dist/lib/components/Toast";

// Components and Contexts
import { SkeletonLoader } from "../../Components/SkeletonLoader/SkeletonLoader";
import DtcoOverviewTable from "./DtcoOverviewTable/DtcoOverviewTable";
import { RefreshTableContext } from "../../contextProviders/RefreshTableContext";
import ProposalDeatils from "../FBPValidation/AddOnComponent/ProposalDeatils";
import NoData from "../../Components/NoData/NoData";

// Services
import { getAPI } from "../../Services/APIservices";

// Models and Constants
import { DtcoModel } from "./DtcoModel";
import { columns } from "./columns";

// styles
import "./DtcoOverviewPage.scss";
import DtcoIteration from "./DtcoIteration/DtcoIteration";
import DtcoErrorDetailsModal from "./DtcoIteration/DtcoErrorDetailsModal";

const DtcoOverviewPage = () => {
  const { requestId } = useParams<{ requestId: string }>();
  const [dtcoData, setDtcoData] = useState<DtcoModel>({
    dtcoSummaries: [],
    dtcoImpacts: null,
    errorDetails: null,
    allowEditDTCOCalculation: false,
    workflowStatusName: "",
    dtcoRequestData: {
      requestName: "",
      createdBy: "",
      dtcoCategoryName: "",
      dtcoTypeName: "",
    },
  });
  const [isTableLoader, setIsTableLoader] = useState(true);
  const [refreshTable, setRefreshTable] = useState(false);
  const [checkRunRefresh, setCheckRunRefresh] = useState(false);
  const [errorDetails, setErrorDetails] = useState<string[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const getDTCOOverviewData = async () => {
    const url = `dtcosummary/GetDTCOOverviewData/${requestId}`;
    setIsTableLoader(true);
    await getAPI(url)
      .then((response) => {
        setDtcoData(response);
        setIsTableLoader(false);
      })
      .catch((error) => {
        toastEmitter(
          {
            className: "customClassName",
            title: "Error occured while fetching DTCO overview data.",
          },
          { type: "error" }
        );
        console.error("An error occurred:", error);
        setIsTableLoader(false);
      });
  };
  useEffect(() => {
    getDTCOOverviewData();
  }, []);

  useEffect(() => {
    if (refreshTable) {
      getDTCOOverviewData();
      setRefreshTable(false);
    } 
  }, [refreshTable]);

  useEffect(() => {
    if (checkRunRefresh) {
      setCheckRunRefresh(false);
      setIsTableLoader(false);
    }
  }, [checkRunRefresh]);

  const handleChangeForDtcoData = (data: DtcoModel) => {
    setIsTableLoader(true);
    setDtcoData(data);
    if (data?.errorDetails !== null && data?.errorDetails?.length > 0) {
      setErrorDetails(data?.errorDetails);
      setOpenErrorModal(true);
    }
    setCheckRunRefresh(true);
  };
  return (
    <RefreshTableContext.Provider value={{ refreshTable, checkRunRefresh, setRefreshTable }}>
      <div className="dtco-overview-page p-3">
        <div className="dtco-overview-page-title">
          <h1 className="header-text mx-3">Create DTCO Proposal</h1>
        </div>
        <div className="proposalDetails">

          <ProposalDeatils
            reuqestName={dtcoData?.dtcoRequestData?.requestName}
            createdBy={dtcoData?.dtcoRequestData?.createdBy}
            driversCategory={dtcoData?.dtcoRequestData?.dtcoCategoryName}
            dtcoType={dtcoData?.dtcoRequestData?.dtcoTypeName}
          />
        </div>
        {!dtcoData?.dtcoRequestData?.isOld && dtcoData?.allowEditDTCOCalculation && (
          <DtcoIteration requestId={requestId} handleDtcoData={handleChangeForDtcoData} refreshRunPerforma={refreshTable} />
        )}

        <div>
          {isTableLoader ? (
            <SkeletonLoader isLoader={isTableLoader}></SkeletonLoader>
          ) : (
                dtcoData?.dtcoSummaries?.length > 0 ? (
                  <>
                    <DtcoOverviewTable
                      headers={columns}
                      data={dtcoData?.dtcoSummaries}
                      requestId={requestId}
                      status={dtcoData?.workflowStatusName}
                      isAllowEdit={dtcoData?.allowEditDTCOCalculation}
                      isDraftRequest={dtcoData?.dtcoRequestData?.isDraftRequest}
                      deploymentUse={dtcoData?.dtcoRequestData?.isDeploymentUseCase}
                      isOld={dtcoData?.dtcoRequestData?.isOld}
                    />

                  </>

                ) : (
                  <div
                    className={` ${dtcoData?.allowEditDTCOCalculation ? "" : "mt-5"}`}
                  >
                    <NoData />
                  </div>

                )
          )
          }
        </div>
        <div>
          <DtcoErrorDetailsModal showErrorModal={openErrorModal} setShowErrorModal={setOpenErrorModal} errorDetails={errorDetails} />
        </div>
      </div>
    </RefreshTableContext.Provider>
  );
};
export default DtcoOverviewPage;
