import { Button, Input, Modal, ReactSelect,TextArea,toastEmitter } from "@anchor/react-components/dist/lib/components";
import { putAPI } from "../../../Services/APIservices";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";

interface IPROPS {
    rowData: any;
    open: boolean;
    dropDownData?: any;
    onClose: () => any;
    reloadAPI?: any;
}

const EditDtcoRequest = ({ rowData, dropDownData, open, onClose, reloadAPI }: IPROPS) => {
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const { handleSubmit, control, formState, reset } = useForm({
        values: {
            requestName: rowData?.requestName,
            drivers: rowData?.drivers,
            dtcoRequestTypes: {
                value: rowData?.dtcoTypeId,
                label: rowData?.dtcoTypeName
            },
            dtcoCategorizations: {
                value: rowData?.categorizationId,
                label: rowData?.dtcoCategoryName
            },
        }
    });
    const { errors } = formState;
    const onSubmit = async (data: any) => {
        setIsBtnDisabled(true)
        const payload = {
            id: rowData?.id,
            requestName: data?.requestName,
            dtcoTypeId: data?.dtcoRequestTypes?.value,
            drivers: data?.drivers,
            categorizationId: data?.dtcoCategorizations?.value
        }
        const response = await putAPI(`DTCO/UpdateDTCORequest/${rowData?.id}`, payload);
        if (response === true) {
            toastEmitter({className: 'customClassName',title: "Request updated successfully"},{type: "success",});
            reloadAPI();
            onClose();
            setIsBtnDisabled(false);
        } else {
            onClose();
            setIsBtnDisabled(false);
        }
    };
const cancelFun = () => {
    onClose();
    reset({
        requestName: rowData?.requestName,
        drivers: rowData?.drivers,
        dtcoRequestTypes: {
            value: rowData?.dtcoTypeId,
            label: rowData?.dtcoTypeName
        },
        dtcoCategorizations: {
            value: rowData?.categorizationId,
            label: rowData?.dtcoCategoryName
        },
    });
}
    return (
        <form data-testid="edit-request-form" onSubmit={handleSubmit(onSubmit)}>
            <Modal
                open={open}
                width="800px"
                height="auto"
                showCloseIcon={false}
                actions={{
                    primaryAction: <Button label="Submit" fit="small" type="submit"  disabled={isBtnDisabled} />,
                    secondaryAction: (
                        <Button
                            label="Cancel"
                            fit="small"
                            onClick={cancelFun}
                            variant="outlined"
                            data-testid="edit-cancel"
                        />
                    ),
                }}
            >
                <div className="modal-headerSection">
                    <h1 className="modal-header">Edit DTCO Request</h1>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="input-section">
                            <Controller
                                name="requestName"
                                control={control}
                                rules={{ required: true}}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        variant="default"
                                        type="text"
                                        fit="small"
                                        id="textInput"
                                        label="Request Name"
                                        value={value}
                                        onChange={(e) => onChange(e)}
                                        required
                                        error = { errors?.requestName ? true : false}
                                        errorMessage="This is the required field"
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="input-section">
                            <Controller
                                name="dtcoRequestTypes"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                        className="react-select"
                                        closeMenuOnSelect
                                        label="DTCO Type"
                                        value={[value]}
                                        customNoOptionsMessage={<p>Not a valid option</p>}
                                        errorMessage="This is error message"
                                        fit="small"
                                        hintPosition="left"
                                        id="dtcoRequestTypes"
                                        isClearable
                                        isSearchable
                                        name="dtcoRequestTypes"
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        options={dropDownData?.dtcoTypes}
                                        orientation="vertical"
                                        suggestType="static"
                                        variant="default"
                                        width={100}
                                        required
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="input-section">
                            <Controller
                                name="dtcoCategorizations"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                        className="react-select"
                                        closeMenuOnSelect
                                        label="Drivers category"
                                        customNoOptionsMessage={<p>Not a valid option</p>}
                                        errorMessage="This is error message"
                                        fit="small"
                                        value={[value]}
                                        hintPosition="left"
                                        id="dtcoCategorizations"
                                        isClearable
                                        isSearchable
                                        name="dtcoCategorizations"
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        options={dropDownData?.dtcoCategorizations}
                                        orientation="vertical"
                                        suggestType="static"
                                        variant="default"
                                        width={100}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="input-section">
                            <Controller
                                name="drivers"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextArea
                                        fit="small"
                                        id="driver"
                                        label="Drivers"
                                        value={value}
                                        onChange={(e) => onChange(e)}
                                        rows={2}
                                        maxLength={4000}
                                        data-testid="edit-inputTextBox"
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </form>
    );
};
export default EditDtcoRequest;
