// React
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// AnchorUI
import {
  Button,
  Icon,
  Modal,
} from "@anchor/react-components/dist/lib/components";

// Components
import EllipsisText from "../../../Components/EllipsisText/EllipsisText";
import DtcoImpactWarningModal from "../DtcoImpactWarningModal/DtcoImpactWarningModal";
import { RedirectionCase } from "../../RequestPage/AddOnPages/RedirectionCase";
import InputRange from "../../../Components/InputRange/InputRange";
// Styles
import "./DtcoOverviewTable.scss";

// Models
import { DtcoModelData, DtcoTableColumn } from "../DtcoModel";

// Services
import { postAPI } from "../../../Services/APIservices";

// Contexts
import { RefreshTableContext } from "../../../contextProviders/RefreshTableContext";
import ImpactProposalModal from "../ImpactProposalModal/ImpactProposalModal";
import { SkeletonLoader } from "../../../Components/SkeletonLoader/SkeletonLoader";
import { appRoles } from "../../../utils/Constants";

interface DtcoOverviewTableProps {
  headers: DtcoTableColumn[];
  data: DtcoModelData[];
  requestId: string | undefined;
  isAllowEdit: boolean | undefined;
  status: string | undefined;
  isDraftRequest?: boolean;
  deploymentUse?: boolean;
  isOld?: boolean;
}

const DtcoOverviewTable = ({
  headers,
  data,
  requestId,
  isAllowEdit,
  status,
  isDraftRequest,
  deploymentUse,
  isOld,
}: DtcoOverviewTableProps) => {
  const userRole = sessionStorage.getItem("userRole");
  const { setRefreshTable } = useContext(RefreshTableContext);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [showImpactModal, setShowImpactModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(data);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [rowIndex, setRowIndex] = useState<number>(0);
  const [nopatRT, setNopatRT] = useState<number>(0);
  let hhPartnerAllocation = 0;
  let bhPartnerAllocation = 0;
  let hhPartnerAllocationPercentage = 0;
  let bhPartnerAllocationPercentage = 0;

  const navigate = useNavigate();

  const handleImpactModalShow = () => {
    setShowImpactModal(true);
  };
  const handleImpactModalClose = () => {
    setShowImpactModal(false);
  };

  const handleDelete = async () => {
    setShowDeleteModal(false);
    const newData = tableData.splice(rowIndex, 1);
    setTableData(newData);

    displayData();
  };

  const updateValue =
    (id: keyof DtcoModelData, rowIndex: number, type: string) =>
    (event: any) => {
      // remove commas from the input
      const reg = /,/g;
      event.target.value = event.target.value.replace(reg, "");
      // validate the input based on the type
      // if (type === "number") {
      //   if (isNaN(Number(event.target.value)) && reg) {
      //    event.target.setCustomValidity("Please enter a valid number");
      //    event.target.reportValidity();
      //    return;
      //   } else {
      //    event.target.setCustomValidity("");
      //    event.target.reportValidity();
      //   }
      // }
      let value =
        type === "boolean" ? event.target.checked : event.target.value.trim();
      data[rowIndex][id] = value as never;
      updateTableData(event, rowIndex, id, type);

      type === "boolean" ? setIsButtonEnabled(false) : setIsButtonEnabled(true);
    };

  const updateTableData = (
    e: any,
    rowIndex: number,
    id: keyof DtcoModelData,
    type: string
  ) => {
    let value = type === "boolean" ? e.target.checked : e.target.value.trim();
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex][id] =
        id === "vesselName" ? (value as never) : (Number(value) as never);
      return newData;
    });
    const allocationMap = {
      hhPartnerAllocation: "hhPartnerAllocationPercentage",
      bhPartnerAllocation: "bhPartnerAllocationPercentage",
      hhPartnerAllocationPercentage: "hhPartnerAllocation",
      bhPartnerAllocationPercentage: "bhPartnerAllocation",
    };

    if (id in allocationMap) {
      if (id.endsWith("Allocation")) {
        calculateAllocationPercentage(rowIndex, id);
        data[rowIndex][allocationMap[id]] =
          id === "hhPartnerAllocation"
            ? hhPartnerAllocation
            : bhPartnerAllocation;
      } else if (id.endsWith("AllocationPercentage")) {
        calculateAllocation(rowIndex, id);
        data[rowIndex][allocationMap[id]] =
          id === "hhPartnerAllocationPercentage"
            ? hhPartnerAllocationPercentage
            : bhPartnerAllocationPercentage;
      }
    }
  };

  const calculateAllocation = async (rowIndex: number, id: string) => {
    const row = data[rowIndex];

    if (
      id === "hhPartnerAllocationPercentage" ||
      id === "bhPartnerAllocationPercentage"
    ) {
      const allocationPercentage = Number(row[id]);
      const capturaIntake = Number(
        row[id.replace("PartnerAllocationPercentage", "CapturaIntake")]
      );

      const result = Number(
        ((allocationPercentage * capturaIntake) / 100).toFixed(2)
      );

      if (id === "hhPartnerAllocationPercentage") {
        hhPartnerAllocationPercentage = result;
      } else {
        bhPartnerAllocationPercentage = result;
      }
    }
  };

  const calculateAllocationPercentage = async (
    rowIndex: number,
    id: string
  ) => {
    const row = data[rowIndex];

    if (id === "hhPartnerAllocation" || id === "bhPartnerAllocation") {
      const allocation = Number(row[id]);
      const capturaIntake = Number(
        row[id.replace("PartnerAllocation", "CapturaIntake")]
      );

      const result = Number(((allocation / capturaIntake) * 100).toFixed(2));

      if (id === "hhPartnerAllocation") {
        hhPartnerAllocation = result;
      } else {
        bhPartnerAllocation = result;
      }
    }
  };

  // Function to display the DtcoData in the table
  const displayData = async () => {
    // To-Do: Need to check if the dual loader is required - psrai
    setLoading(true);
    const updatedTableData = tableData.map((row) => {
      return {
        ...row,
        isImpactBaseline: row.isImpactBaseline ? true : false,
      };
    });
    const url = `dtcosummary/SaveDTCOCalculation/${requestId}`;
    try {
      const response = await postAPI(url, updatedTableData);
      if (response) {
        setRefreshTable(true);
        setIsButtonEnabled(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    handleImpactModalClose();
  };

  // Function to mark the data as complete
  const calculateImpact = () => {
    handleImpactModalShow();
  };

  const handleClose = () => {
    handleImpactModalClose();
  };

  const removeVessel = (rowIndex: number) => () => {
    setRowIndex(rowIndex);
    setShowDeleteModal(true);
  };

  // function to check if any row has empty values for vesselName
  const checkEmptyVesselName = () => {
    return tableData.some((row) => !row.vesselName);
  };

  // function to check if any row has isBaseline as true
  const checkBaseline = () => {
    return tableData.some((row) => {
      return row.isImpactBaseline;
    });
  };

  const preventOnWheelChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // function isBaselineVesselPresent to return true if the vesselName is already marked as baseline for the same serviceId
  const isBaselineVesselPresent = (rowIndex: number) => {
    return tableData.some((row, index) => {
      return (
        row.isImpactBaseline &&
        row.serviceId === tableData[rowIndex].serviceId &&
        rowIndex !== index
      );
    });
  };

  // function to return true for the vessels which are not isImpactBaseline and having same vesselName
  const isBaselineVessel = (vesselName: string) => {
    return tableData.some(
      (row) => row.vesselName === vesselName && row.isImpactBaseline
    );
  };
  const getNopatRT = (nopat: number, roundTripDays: number) => {
    const nopatPerday = nopat / 365;
    const roundTrip = roundTripDays * nopatRT;
    return (nopatPerday * roundTrip).toLocaleString();
  };
  const renderCell = (header, row, rowIndex, isAllowEdit, updateValue) => {
    const commonProps = {
      className: "text-end px-3",
      children: row[header.id as keyof DtcoModelData],
    };
    switch (header.id) {
      case "serviceName":
        return (
          <div
            className="d-flex flex-row justify-content-between align-items-start px-3"
            style={{
              backgroundColor: row.serviceNameColor ?? "white",
              padding: "10px",
              margin: "-5px",
              color: row.serviceNameColor === "white" ? "black" : "white",
            }}
          >
            <div className="w-100">
              <label className="w-100">
                <input
                  type="checkbox"
                  className="d-none"
                  defaultChecked={row["isImpactBaseline"]}
                  onChange={updateValue(
                    "isImpactBaseline",
                    rowIndex,
                    "boolean"
                  )}
                  disabled={
                    !isAllowEdit ||
                    ((isBaselineVesselPresent(rowIndex) ||
                      isBaselineVessel(row.vesselName)) &&
                      !row.isImpactBaseline)
                  }
                />
                <span
                  className={`text-truncate ${
                    !isAllowEdit ||
                    ((isBaselineVesselPresent(rowIndex) ||
                      isBaselineVessel(row.vesselName)) &&
                      !row.isImpactBaseline)
                      ? "disabled-text fw-light"
                      : ""
                  }`}
                >
                  <EllipsisText
                    text={
                      isOld
                        ? row[header.id]
                        : row[header.id] + " (" + row.rotationCode + ")"
                    }
                    maxLength={18}
                  />
                </span>
              </label>
            </div>
            {isAllowEdit && (
              <div
                style={{
                  float: "right",
                  cursor: "pointer",
                }}
              >
                <Icon
                  name="trash"
                  className="fs-6"
                  onClick={removeVessel(rowIndex)}
                />
              </div>
            )}
          </div>
        );
      case "roundTripDistance":
      case "roundTripDays":
      case "dailyConsumption":
      case "tc":
      case "roundTripCost":
      case "yearlyCost":
      case "hhmskAllocation":
      case "bhmskAllocation":
      case "roundTripMSKAllocation":
      case "roundTripDemand":
      case "mskYearAllocation":
      case "mskYearDemand":
      case "slotCost":
      case "unitCost":
      case "serviceRanking":
      case "overallRanking":
      case "tcDifference":
      case "mtCo2":
      case "gCo2":
      case "nopat":
        return (
          <div className="d-flex justify-content-between">
            {header.dollarSign && <span className="px-1 dollar-sign">$</span>}
            {!header.dollarSign && <span className="px-1"></span>}
            <div
              className={`text-end px-3 ${
                header.id === "nopat" ? "fw-semibold" : ""
              }`}
            >
              {Number(row[header.id as keyof DtcoModelData]).toLocaleString()}
            </div>
          </div>
        );

      case "consupmtionInTons":
        const sulpherPercentageObj = row.sulpherPercentage
          ?.split(",")
          .reduce((acc, curr) => {
            const [key, value] = curr.split(")");
            acc[key.slice(1)] = value;
            return acc;
          }, {});
        const headers = ["3.5%", "0.5%", "0.1%"];

        return (
          <>
            <div className="text-end px-3">
              {Number(row[header.id as keyof DtcoModelData]).toLocaleString()}
            </div>
            <table className="table-bordered text-center w-100 subTable">
              <thead>
                <tr className="fw-lighter">
                  {headers.map((header, index) => (
                    <th key={"sulpher-percent" + index} className="small-font">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr className="fw-lighter">
                  {headers.map((header, index) => (
                    <td key={"sulpher-value" + index} className="small-font">
                      {sulpherPercentageObj?.[header] || "-"}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </>
        );

      case "designClass":
      case "vesselName":
        return (
          <div className="text-end px-3">
            <EllipsisText text={row[header.id]} maxLength={20} />
          </div>
        );

      case "nominalCapacity":
      case "tcd":
      case "consumptionInUSD":
      case "oneOffCost":
      case "hhCapturaIntake":
      case "hhPartnerAllocation":
      case "hhDryDemand":
      case "hhReeferDemand":
      case "hhDryCY":
      case "hhReeferCY":
      case "bhCapturaIntake":
      case "bhPartnerAllocation":
      case "bhDryDemand":
      case "bhReeferDemand":
      case "bhDryCY":
      case "bhReeferCY":
      case "allocation":
      case "bhPartnerAllocationPercentage":
      case "hhPartnerAllocationPercentage":
      case "portExpense":
        return (
          <div className="input-group">
            {header.dollarSign && (
              <span className="input-group-text p-1 dollar-sign">$</span>
            )}
            <input
              type="number"
              className="form-control text-end"
              placeholder=""
              onChange={updateValue(
                header.id as keyof DtcoModelData,
                rowIndex,
                header.type
              )}
              onWheel={preventOnWheelChange}
              value={row[header.id as keyof DtcoModelData]}
              disabled={!isAllowEdit}
            />
            {(header.id === "bhPartnerAllocationPercentage" ||
              header.id === "hhPartnerAllocationPercentage" ||
              header.id === "allocation") && (
              <span className="input-group-text p-1" id="basic-addon1">
                %
              </span>
            )}
          </div>
        );
      case "nopatRT":
        return (
          <div className="d-flex justify-content-between">
            {!header.dollarSign && <span className="px-1"></span>}
            {header.dollarSign && <span className="px-1 dollar-sign">$</span>}
            <div className="text-end px-3">
              {getNopatRT(row.nopat, row.roundTripDays)}
            </div>
          </div>
        );
      case "bhUtilization":
      case "hhUtilization":
      case "hhPerToNominal":
      case "bhPerToNominal":
        return (
          <div
            className={`text-end ${
              row[header.id] > 100 ? "text-danger fw-semibold" : ""
            }`}
          >
            {row[header.id] || 0}
            <span className="px-1">%</span>
          </div>
        );

      case "nopatDay":
        return (
          <div className="d-flex justify-content-between">
            {!header.dollarSign && <span className="px-1"></span>}
            {header.dollarSign && <span className="px-1 dollar-sign">$</span>}
            <div className="text-end px-3">
              {Number(row.nopat / 365).toLocaleString()}
            </div>
          </div>
        );
      default:
        return <div {...commonProps} />;
    }
  };
  const getCounter = (value: number) => {
    setNopatRT(value);
  };
  const getDropdown = (header: any) => {
    return (
      <span className="dropdownDiv">
        <span>{header.header}</span>
        <span className="dropDown-left">
          <InputRange valueHandler={getCounter} maxValue={20} minValue={1} />
        </span>
      </span>
    );
  };
  const Table = ({ headers, tableData, isAllowEdit, updateValue }) => (
    <table className="dtcoOverviewTable">
      <tbody>
        {headers.map((header: DtcoTableColumn, index: number) => (
          <tr key={header.header + index}>
            {(isOld ||
              !(
                header.id === "capturaIntake" || header.id === "allocation"
              )) && (
              <th
                className={`dtcoOverviewTable__header dtcoOverviewTable__sticky-column text-uppercase ${
                  header.isRowSeparator ? "fw-bold" : ""
                } ${
                  header.id === "ranking" ||
                  header.id === "headHaul" ||
                  header.id === "backHaul" ||
                  header.id === "carbonEmission" ||
                  header.id === "intakeCalucation"
                    ? "highlight-text"
                    : ""
                }`}
              >
                {header.id === "nopatRT" ? getDropdown(header) : header.header}
              </th>
            )}
            {(isOld ||
              !(header.id === "capturaIntake" || header.id === "allocation")) &&
              tableData.map((row, rowIndex: number) => (
                <td
                  key={header.accessorKey + rowIndex}
                  id={`${header.id}${rowIndex}`}
                  className={`dtcoOverviewTable__data ${
                    row["isImpactBaseline"] ? "bg-selected-baseline" : ""
                  } ${
                    row["isImpactBaseline"] && header.id === "serviceName"
                      ? "bg-top-baseline"
                      : ""
                  } ${
                    header.id === "gCo2" && row["isImpactBaseline"]
                      ? "bg-bottom-baseline"
                      : ""
                  }`}
                >
                  {renderCell(header, row, rowIndex, isAllowEdit, updateValue)}
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <>
      {isAllowEdit ? (
        <div className="button-placement pb-3 pt-3">
          <div className="d-flex flex-row float-end">
            {(!checkBaseline() ||
              checkEmptyVesselName() ||
              tableData.length < 2 ||
              isButtonEnabled) && (
              <Button
                appearance="inverse"
                fit="small"
                icon="question-circle"
                id=""
                justifyItems="center"
                label=""
                name=""
                onClick={() => setShowInfoModal(true)}
                title=""
                variant="filled"
              />
            )}
            <Button
              className="button mx-2"
              appearance="default"
              fit="small"
              id="primary"
              justifyItems="center"
              label="Impact Analysis"
              name="primary"
              onClick={calculateImpact}
              variant="outlined"
              disabled={
                checkEmptyVesselName() ||
                !checkBaseline() ||
                tableData.length < 2 ||
                isButtonEnabled
              }
            />
            {!isOld && (
              <Button
                appearance="default"
                className="button"
                fit="small"
                id="primary"
                loading={loading}
                justifyItems="center"
                label="Generate Ranking"
                name="primary"
                onClick={displayData}
                title="Submit"
                variant="filled"
                disabled={!isButtonEnabled}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="button-placement pb-3 pt-3">
          {status !== "DPLAssessment" && (
            <div className="d-flex flex-row float-end">
              <Button
                className="button mx-2"
                appearance="default"
                fit="small"
                id="continue"
                justifyItems="center"
                label="Continue"
                name="continue"
                variant="outlined"
                onClick={() => {
                  navigate(
                    RedirectionCase(
                      Number(requestId),
                      status,
                      userRole
                    ) as never
                  );
                }}
              />
            </div>
          )}
          {userRole === appRoles.DeploymentUser &&
            status === "DPLAssessment" &&
            isDraftRequest === true && (
              <div className="d-flex flex-row float-end">
                <Button
                  className="button mx-2"
                  appearance="default"
                  fit="small"
                  id="continue"
                  justifyItems="center"
                  label="Continue"
                  name="continue"
                  variant="outlined"
                  onClick={() => {
                    navigate(
                      RedirectionCase(
                        Number(requestId),
                        status,
                        userRole
                      ) as never
                    );
                  }}
                />
              </div>
            )}
        </div>
      )}
      <div
        className="overflow-x-auto"
        style={{ marginTop: isAllowEdit ? 0 : "4.5em" }}
      >
        {loading ? (
          <SkeletonLoader isLoader={true}></SkeletonLoader>
        ) : (
          Table({ headers, tableData, isAllowEdit, updateValue })
        )}
      </div>
      {showImpactModal && (
        <ImpactProposalModal
          tableData={tableData}
          requestId={requestId}
          show={showImpactModal}
          onHide={handleClose}
          onConfirm={handleConfirm}
          deploymentUse={deploymentUse}
          isOld={isOld}
        />
      )}
      <Modal
        actions={{
          primaryAction: (
            <Button label="Confirm" onClick={handleDelete} fit="small" />
          ),
          secondaryAction: (
            <Button
              label="Dismiss"
              fit="small"
              variant="outlined"
              onClick={() => setShowDeleteModal(false)}
            />
          ),
        }}
        backdropcloseactiondisabled
        heading="Delete"
        dimension="small"
        open={showDeleteModal}
        showCloseIcon={true}
        onClose={() => setShowDeleteModal(false)}
      >
        <div className="p-2">
          <p>
            Are you sure you want to delete vessel{" "}
            {tableData[rowIndex]?.vesselName} from{" "}
            {tableData[rowIndex]?.serviceName}?
          </p>
        </div>
      </Modal>

      <DtcoImpactWarningModal
        showInfoModal={showInfoModal}
        setShowInfoModal={setShowInfoModal}
        checkBaseline={checkBaseline}
        checkEmptyVesselName={checkEmptyVesselName}
        madeChanges={isButtonEnabled}
        vesselsLength={tableData.length}
      />
    </>
  );
};

export default DtcoOverviewTable;
