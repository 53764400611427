import type {BarData} from '../Components/Charts/model';
export class LocationModel {
  id?: number;
  parentId?: number;
  name?: string;
  constructor() {
    this.id = 0;
    this.parentId = 0;
    this.name = "";
  }
}
export const enum appRoles {
  'DeploymentHead' = 'DeploymentHead',
  'DeploymentUser' = 'DeploymentUser',
  'HONP' = 'HeadOfOceanNetworkProduct',
  'FBP' = 'FinanceBusinessPartner',
}

export const BarChartData: BarData[] = [
  {
    dataKey: 'opportunity',
    stackId: 'a',
    fill: '#6A91BC',
  },
  {
    dataKey: 'savings',
    stackId: 'a',
    fill: '#F28E2A',
  }
];
export const StatusListForPerforma = [
  'ACTIVE',
  'APPROVAL',
  'APPROVED',
  'DESIGN',
  'EXPIRED',
  'PLANNING',
  'SCHEDULING',
]