// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-section {
  height: 50px;
  background: var(--color-white);
  padding: 10px;
  box-shadow: 0px 12px 14px 2px rgba(0, 0, 0, 0.031372549);
  border-bottom: solid 1px #e5e5e5;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 999;
}
.header-section .notification {
  text-align: right;
  font-size: var(--font-size-xl);
}
.header-section .timer {
  font-size: var(--font-size-xl);
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-text-grey);
}
.header-section .time-with-notification {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0px;
  position: relative;
}
.header-section .it-help a {
  color: var(--color-maersk-blue);
  font-weight: 500;
  text-decoration: none;
  margin: 0px 10px 0px 5px;
}
.header-section .timezone {
  color: var(--time-zone-text-color);
  margin-top: 4px;
  width: 16rem;
  white-space: nowrap;
  background: #daf5ff;
  padding: 0px 10px;
  line-height: 22px;
  border-radius: 3px;
}
.header-section .time-with-userDetails {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/Layouts/Header/Header.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,8BAAA;EACA,aAAA;EACA,wDAAA;EACA,gCAAA;EACA,eAAA;EACA,WAAA;EACA,QAAA;EACA,YAAA;AACF;AAAE;EACE,iBAAA;EACA,8BAAA;AAEJ;AAAE;EACE,8BAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,6BAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AACG;EACC,+BAAA;EACA,gBAAA;EACA,qBAAA;EACA,wBAAA;AACJ;AAEE;EACE,kCAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,aAAA;AAAJ","sourcesContent":[".header-section {\n  height: 50px;\n  background: var(--color-white);\n  padding: 10px;\n  box-shadow: 0px 12px 14px 2px #00000008;\n  border-bottom: solid 1px #e5e5e5;\n  position: fixed;\n  width: 100%;\n  top: 0px;\n  z-index: 999;\n  .notification {\n    text-align: right;\n    font-size: var(--font-size-xl);\n  }\n  .timer {\n    font-size: var(--font-size-xl);\n    font-weight: 400;\n    line-height: 24px;\n    letter-spacing: 0em;\n    text-align: center;\n    color: var(--color-text-grey);\n  }\n  .time-with-notification{\n    display: flex;\n    align-items: center;\n    margin-left: auto;\n    margin-right: 0px;\n    position: relative;\n  }\n  .it-help{\n   a{\n    color: var(--color-maersk-blue);\n    font-weight: 500;\n    text-decoration: none;\n    margin: 0px 10px 0px 5px;\n   }\n  }\n  .timezone {\n    color: var(--time-zone-text-color);\n    margin-top: 4px;\n    width: 16rem;\n    white-space: nowrap;\n    background: #daf5ff;\n    padding: 0px 10px;\n    line-height: 22px;\n    border-radius: 3px;\n  }\n  .time-with-userDetails{\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
