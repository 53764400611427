import React, { useEffect } from "react";
import './Feedback.scss'
import { PopupButton } from "@maersk-global/insightshub-embed-react";
import { getUserMailID } from "../../utils/token";
import { postAPI } from "../../Services/APIservices";
import { Icon, Modal } from "@anchor/react-components/dist/lib/components";

interface IPROPS {
  isOpen?: boolean;
  onClose?: any;
  checkFeedbackOption?: "MonthlySUS" | "QuaterlyNPS" | "feedbackclick" | "NPSandSUS";
}

const FeedbackOpenPopUp = ({ isOpen, onClose, checkFeedbackOption }: IPROPS) => {
  const [userEmail, setUserEmail] = React.useState<string>("");
  const displayWarningMsg = checkFeedbackOption === "MonthlySUS" ? 'Monthly' : checkFeedbackOption === "QuaterlyNPS" ? 'Quaterly' : 'monthly for SUS and Quaterly for NPS';
  const getUserMail = async () => {
    const email = await getUserMailID();
    if (email) {
      setUserEmail(email);
    }
  }

  useEffect(() => {
    getUserMail();
  }, []);

  const handleSubmitForNPS = async (data: any) => {
    try {
      const response = await postAPI(`UserFeedBack/AddUserFeedBackAsync/nps`);
      if (response) {
        onClose();
      }
    } catch (error) {
      console.log("error is ", error);
    }
  }
  const handleSubmit = async (data: any) => {
    try {
      const response = await postAPI(`UserFeedBack/AddUserFeedBackAsync/sus`);
      if (response) {
        onClose();
      }
    } catch (error) {
      console.log("error is ", error);
    }
  };
  return (
    <Modal
      open={isOpen}
      height="auto"
      width="900px"
      className="feedback-modal"
      heading='Feedback'
      showCloseIcon={true}
      backdropcloseactiondisabled
      onClose={onClose}
    >

      <div className="feedback-modal-details">
        <div className="row">
          {
            checkFeedbackOption !== "feedbackclick" && <p>Note: This form is Mandatory for the Deployment user to fill {displayWarningMsg} . </p>
          }
          
          {
            (checkFeedbackOption === "MonthlySUS" || checkFeedbackOption === "feedbackclick" || checkFeedbackOption === "NPSandSUS") &&
            (<div className="col-md-6">
              <div className="main-div" data-testid="fixture-button">
                <div className="top-div">
                  <span className="icon">
                    <Icon name="comment-dots" size="20"></Icon>
                  </span>
                  <span className="text">
                    <h5>SUS</h5>
                    <p>
                      Please click on Feedback to fill the SUS Feedback Form.
                    </p>
                  </span>
                </div>
                <div className="btn-div">
                  <PopupButton
                    id={process.env.REACT_APP_FEEDBACK_ID ?? ""}
                    className="btn btn-primary"
                    email={userEmail}
                    onSubmit={handleSubmit}
                  >
                    Feedback
                  </PopupButton>
                </div>
              </div>
            </div>)
          }
          {
            (checkFeedbackOption === "feedbackclick" || checkFeedbackOption === "NPSandSUS" || checkFeedbackOption === 'QuaterlyNPS') && (
              <div className="col-md-6">
                <div className="main-div">
                  <div className="top-div">
                    <span className="icon">
                      <Icon name="comment-dots" size="20"></Icon>
                    </span>
                    <span className="text">
                      <h5>NPS</h5>
                      <p>
                        Please Click on Feedback  to fill the
                        NPS Feedback Form.
                      </p>
                    </span>
                  </div>
                  <div className="btn-div">
                    <PopupButton
                      id={process.env.REACT_APP_NPS_FEEDBACK_ID ?? ""}
                      className="btn btn-primary"
                      email={userEmail}
                      onSubmit={handleSubmitForNPS}
                      variant="scroll"
                    >
                      Feedback
                    </PopupButton>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </Modal>
  )
};
export default FeedbackOpenPopUp;