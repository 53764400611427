import React, { useEffect, useState } from "react";
import { StepperIndicator } from "@anchor/react-components";
import { getAPI } from "../../Services/APIservices";
export interface StepIndicatorModel {
  requestStageOrder: number;
  workflowStages: [];
}
interface IPROPS {
  requestID?: string | null;
  triggerPoint?: boolean;
}
const StepIndicator = ({ requestID, triggerPoint }: IPROPS) => {
  const [workflow, setworkflow] = useState<StepIndicatorModel>();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const getWorkflow = async () => {
    const response = await getAPI(
      `dtcosummary/GetDTCORequestWorkflowStages/${requestID}`
    );
    setworkflow(response);
    if(response){
        const index = response?.workflowStages?.findIndex((p:any) => p.displayOrder === response.requestStageOrder);
        setActiveIndex(index+1)
    }
  };
  useEffect(() => {
    if (triggerPoint) {
      getWorkflow();
    }
  }, [triggerPoint]);
  return (
    <div className="step-Indicator">
      <div className="flex-step">
        <StepperIndicator
          activeColor="primary.blue.300"
          currentindex={activeIndex}
          customIcon
          onClick={function noRefCheck() {}}
          steps={workflow?.workflowStages}
        />
      </div>
    </div>
  );
};
export default StepIndicator;