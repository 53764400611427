export const AppString = {
  acceptModalTxt: "Are you sure you want to accept this proposal?",
  acceptModalHeader: "Accept proposal",
  acceptToastComment: "Request has been accepted successfully",
  completeModalTxt: "Complete Proposal",
  rejectedToastComment: "Request has been rejected successfully",
  errorToastComment: "Something went wrong",
  errorMsgForRequiredFields: "This is the required field",
  savedSuccessfullyToastComment: "Saved successfully",
  DEPEscalated: "Are you sure you want to escalate the proposal?",
  DEPReject: "Are you sure you want to accept rejection and complete the flow?",
  completeBody: "Are you sure you want to Complete this proposal?",
  errMsgForRotation: 'This is the required field',
};
