// React
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@anchor/react-components/dist/lib/components";
// Styles
import "./SideNav.scss";

// Components
import SideBarData from "./SideBarData";
import FeedbackOpenPopUp from "../../pages/Feedback/FeedbackOpenPopUp";
interface IPROPS {
  onSideClick?: any;
  accessDenied?: boolean;
}
const SideNav = ({ onSideClick, accessDenied }: IPROPS) => {
  const filteredSideBarData = SideBarData;
  const [sidebar, setSidebar] = useState(false);
  const location = useLocation();
  const [openFeedback, setOpenFeedback] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
    onSideClick(sidebar);
  };
  const checkSubNavMenu = (item: any) => {
    if (item.path == location.pathname) {
      return true;
    } else if (
      item?.subNav &&
      item.subNav?.map((i: any) => i?.path == location.pathname)?.[0]
    ) {
      return true;
    } else {
      return false;
    }
  };
  const setTheFeedbackPopupOpen = () => {
    setOpenFeedback(true);
  }

  return (
    <>
      {
        <FeedbackOpenPopUp isOpen={openFeedback} checkFeedbackOption={"feedbackclick"} onClose={() => setOpenFeedback(false)} />
      }
      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <ul className="nav-menu-items">
          <span
            className="arrowclick"
            data-testid="showSidebar"
            onClick={showSidebar}
          >
            {
              <Icon
                name={sidebar ? "double-chevron-left" : "double-chevron-right"}
              ></Icon>
            }
          </span>
          {filteredSideBarData.map((item: any , index: number) => {
            return (
              <li
                key={item?.key}
                className={`${item.cName} ${checkSubNavMenu(item) && "active"}`}
              >
                <Link to={item.path} className={!sidebar ? "bottom-title" : ""}>
                  <span className={!sidebar ? "icon-view" : ""}>
                    {" "}
                    <Icon
                      name={item.icon}
                      color={checkSubNavMenu(item) ? "#42b0d5" : "#000000"}
                    ></Icon>
                  </span>
                  <span
                    className={!sidebar ? "tittle-hide" : ""}
                    data-testid={item.id}
                  >
                    {item.title}
                  </span>
                </Link>
              </li>
            );
            // }
          })}
         <li
          key={"key1"}
          className={sidebar ? 'nav-text feedback-button-active' : 'nav-text feedback-button'}
        >
          <div className="feedback-name" onClick={setTheFeedbackPopupOpen} >          
            <div className={!sidebar ? "bottom-title" : ""}>
              <span className={!sidebar ? "icon-view" : ""}>
                {""}
                <Icon
                  name={"comment-dots"}
                  color={"#ffffff"}
                ></Icon>
              </span>
              <span
                className={!sidebar ? "title-hide" : ""}
              >
                {'Feedback'}
              </span>
            </div>
         </div>
        </li>
      </ul>
    </nav>
    </>
  );
};
export default SideNav;
