// React
import React from "react";

const SideBarData = [
  {
    title: "Home",
    path: "/",
    icon: "house",
    cName: "nav-text",
    page: "cep",
    id: "menu-dashboardPage",
    key: 1
  },
  {
    title: "DTCO Request Page",
    path: "/RequestPage",
    icon: "warehouse",
    cName: "nav-text",
    key: 2
  }
];
export default SideBarData;
