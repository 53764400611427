export const RedirectionCase = (
  id?: number,
  status?: string,
  loggedInUserRole?: string | null,
  isDraftRequest?:boolean
) => {
  if (loggedInUserRole === "DeploymentUser") {
    switch (status) {
      case "DPL Assessment":
        return isDraftRequest ? `/FBPvalidation?requestId=${id}` : `/DtcoOverviewPage/${id}`;
      default:
        return `/DeploymentValidation?requestId=${id}`;
    }
  }
  if (
    loggedInUserRole === "DeploymentHead" ||
    loggedInUserRole === "FinanceBusinessPartner" ||
    loggedInUserRole === "HeadOfOceanNetworkProduct"
  ) {
    switch (status) {
      case "DPL Assessment":
        return `/DtcoOverviewPage/${id}`;
      default:
        return `/ProposalValidationScreen/${id}`;
    }
  }
};
