import React, { useState } from "react";
import {
  Button,
  Modal,
  ReactSelect,
  Input,
  toastEmitter,
  TextArea,
  Checkbox,
} from "@anchor/react-components/dist/lib/components";
import { useForm, Controller } from "react-hook-form";
import { postAPI } from "../../../Services/APIservices";
interface IPROPS {
  isOpen?: boolean;
  dropDownData?: any;
  onClose?: any;
  reloadAPI?: any;
}

const CreateRequest = ({
  isOpen,
  dropDownData,
  onClose,
  reloadAPI,
}: IPROPS) => {
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const { handleSubmit, control, formState, reset } = useForm({
    defaultValues: {
      requestName: undefined,
      drivers: "",
      deploymentUse:false,
      dtcoRequestTypes: [],
      dtcoCategorizations: [],
    },
  });
  const { errors } = formState;
  const onSubmit = async (data: any) => {
    setIsBtnDisabled(true);
    const payload = {
      id: 0,
      requestName: data?.requestName,
      dtcoTypeId: data?.dtcoRequestTypes?.value,
      drivers: data?.drivers,
      IsDeploymentUseCase: data?.deploymentUse,
      categorizationId: data?.dtcoCategorizations?.value,
    };
    const response = await postAPI(`DTCO/AddDTCORequest`, payload);
    if (response === true) {
      toastEmitter(
        { className: "customClassName", title: "Request added successfully" },
        { type: "success" }
      );
      reloadAPI();
      onClose();
      setIsBtnDisabled(false);
    } else {
      onClose();
      setIsBtnDisabled(false);
    }
    reset({});
  };
  const cancelFun = () => {
    onClose();
    reset({});
  };
  return (
    <form data-testid="create-request-form" onSubmit={handleSubmit(onSubmit)}>
      <Modal
        open={isOpen}
        width="800px"
        height="420px"
        showCloseIcon={false}
        actions={{
          primaryAction: (
            <Button
              label="Submit"
              disabled={isBtnDisabled}
              fit="small"
              type="submit"
            />
          ),
          secondaryAction: (
            <Button
              label="Cancel"
              fit="small"
              data-testid="cancel"
              onClick={cancelFun}
              variant="outlined"
            />
          ),
        }}
      >
        <div className="modal-headerSection">
          <h1 className="modal-header">Create Request</h1>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <div className="input-section">
              <Controller
                name="requestName"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    variant="default"
                    type="text"
                    fit="small"
                    id="textInput"
                    label="Request Name"
                    onChange={(e) => onChange(e)}
                    required
                    error={errors.requestName ? true : false}
                    errorMessage="This is the required field"
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="input-section">
              <Controller
                name="dtcoRequestTypes"
                control={control}
                render={({ field: { onChange } }) => (
                  <ReactSelect
                    className="react-select"
                    closeMenuOnSelect
                    label="DTCO Type"
                    customNoOptionsMessage={<p>Not a valid option</p>}
                    errorMessage="This is error message"
                    fit="small"
                    isSearchable
                    hintPosition="left"
                    id="dtcoRequestTypes"
                    isClearable
                    name="dtcoRequestTypes"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    options={dropDownData?.dtcoTypes}
                    orientation="vertical"
                    suggestType="static"
                    variant="default"
                    width={100}
                    required
                    aria-label="DTCO Type"
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="input-section">
              <Controller
                name="dtcoCategorizations"
                control={control}
                render={({ field: { onChange } }) => (
                  <ReactSelect
                    className="react-select"
                    closeMenuOnSelect
                    label="Drivers category"
                    customNoOptionsMessage={<p>Not a valid option</p>}
                    errorMessage="This is error message"
                    fit="small"
                    hintPosition="left"
                    id="dtcoCategorizations"
                    isClearable
                    isSearchable
                    name="dtcoCategorizations"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    options={dropDownData?.dtcoCategorizations}
                    orientation="vertical"
                    suggestType="static"
                    variant="default"
                    width={100}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-12 mb-1">
            <div className="input-section">
              <Controller
                name="drivers"
                control={control}
                render={({ field: { onChange } }) => (
                  <TextArea
                  data-testid="inputTextBox"
                    fit="small"
                    id="textInput"
                    label="Drivers"
                    onChange={(e) => onChange(e)}
                    rows={2}
                    maxLength={4000}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="input-section">
              <Controller
                name="deploymentUse"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    id="checkbox"
                    checked={value ? true : false }
                    name="checkbox"
                    label="For deployment use only"
                    onChange={(e)=>{
                      onChange(e.target.checked)
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </Modal>
    </form>
  );
};
export default CreateRequest;
